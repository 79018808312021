import React from 'react';

import Layout from 'molecules/Layout';
import NotFound from 'organisms/NotFound';

import 'styles/pages/404.scss';

interface Props {}

const NotFoundPage: React.FC<Props> = () => {
  return (
    <Layout
      searchEngineOptimization={() => (
        <Layout.SEO path="/404" title="404: Not found" lang="de-DE" />
      )}
    >
      <NotFound />
    </Layout>
  );
};

export default NotFoundPage;
