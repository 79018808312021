import React from 'react';

export interface Props {}

const NotFound: React.FC<Props> = () => {
  return (
    <div className={`NotFound`}>
      <h1>Hoppla, nichts gefunden!</h1>
      <p>Die gesuchte Seite wurde leider nicht gefunden.</p>
    </div>
  );
};

export default NotFound;
